/* import React, {useEffect, useRef, useState} from "react"; */
import "./Footer.css";
import { Heading, VStack, /* useColorModeValue */ } from "@chakra-ui/react";


const Footer = (props) => {

  return (
    <section id="footer-section">
      <VStack
        backgroundColor="black"//{props.bgColor}
        color="white"//{props.color}
        /* paddingTop="3%" */
        alignItems="center"
        justifyContent="center"
        spacing={0}
        zIndex="106"
        width="100%"
        height="fit-content"
        display="flex"
        position="relative"
        /* top="-2px" */
        /* paddingTop={{ base: "5vh"}} */
      >
        <VStack class="footer" w="100%" p={2} /* marginTop={{ base: "5vh"}} */ /* alignItems="flex-start" */>      
          <Heading>
                © Copyright 2023-2024
                < br/> André Melo / andre1melo
                {/* < br/>< br/> This is not a template or reusable theme. */}
          </Heading>
        </VStack>
      </VStack>
    </section>
  );
};

export default Footer;