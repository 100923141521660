import { useRef, useState, useEffect } from 'react';
import "./Home.css";
import { Box, useColorMode, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
/* import { Avatar, Heading, VStack, Stack, Box, Image, useColorModeValue } from "@chakra-ui/react";
import { theme } from './Theme'; */

/* import Header from "./Header"; */

import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { CustomWiggle } from "gsap/CustomWiggle";
import { CustomEase } from "gsap/CustomEase";
import { GSDevTools } from "gsap/GSDevTools";
import SplitType from 'split-type'

import ToggleButton from "./Toggle";
import Nav from "./Nav";
import About from './About';
import Projects from './Projects';
import Contact from "./Contact"
import Footer from "./Footer"
import SmallPlaneWithBanner from "./SmallPlaneWithBanner";
import SmallPlaneWithBanner2 from "./SmallPlaneWithBanner2";
/* import KnockUpStreamSVG2 from "./KnockUpStreamSVG2";
import KnockUpStreamSVG from "./KnockUpStreamSVG"; */
/* import Lab from "./Lab" */

gsap.registerPlugin(
  useGSAP,
  ScrollTrigger,
  ScrollSmoother,
  ScrollToPlugin,
  Draggable,
  InertiaPlugin,
  SplitText,
  CustomEase,
  CustomWiggle,
  GSDevTools);

const Home = () => {
  // Detecting the rendering engine
  let isWebKit = navigator.userAgent.indexOf('AppleWebKit') > -1;
  let isGecko = navigator.userAgent.indexOf('Gecko') > -1 && navigator.userAgent.indexOf('KHTML') === -1;
  let isBlink = navigator.userAgent.indexOf('Chrome') > -1; // Blink is used by Chrome and Edge

  /* if (isWebKit) {
    console.log('The browser is using the WebKit rendering engine');
  } else if (isGecko) {
    console.log('The browser is using the Gecko rendering engine '+isGecko);
  } else if (isBlink) {
    console.log('The browser is using the Blink rendering engine');
  } else {
    console.log('The rendering engine is unknown');
  } */

  /* const wrapper = useRef(); */
  const main = useRef();
  const smoother = useRef();
  
 /* ===== Theme Colors ===== */
 const {colorMode, toggleColorMode} = useColorMode();
 const color = useColorModeValue('#ffffff', '#000000')
 const colorName = useColorModeValue('white', 'black')
 const halfColor = useColorModeValue('rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.75)')
 const bgColor = useColorModeValue('#000000', '#ffffff')
 const socialBorderColor = useColorModeValue('#ffffff33', '#00000033')
 const currentTheme = useColorModeValue('Night', 'Day')
  /* const colorHover = useColorModeValue('#fff', '#000') */
  /* const headerBG = useColorModeValue('#00000080', '#ffffff80') */
  /* const currentTheme = useColorModeValue('Dark', 'Light') */

  /* ===== Toggle Dark/Light ===== */
  const [isDark, setDark] = useState(false);
  /* const delayToggleColorMode = () => {
    setDark((prev) => !prev);
    //console.log(isDark)
    setTimeout(() => {
      //toggleColorMode();
      //ScrollTrigger.refresh();
    }, 350)
  } */

  /* ===== Media Queries ===== */
    const [noHover] = useMediaQuery('(hover: none)')
    const [portrait] = useMediaQuery('(orientation: portrait)')
    const [under992] = useMediaQuery('(max-width: 992px)')
    const [under768] = useMediaQuery('(max-width: 767px)')
    const [hUnder376] = useMediaQuery('(max-height: 376px)')
  
  /* ===== Mobile Gecko Querie ===== */
    function mobileGecko() {
      return noHover
      ? (isGecko ? true : false )
      : false
    }

  /* ===== Space BG ===== */
    const theme = useColorModeValue('Dark', 'Dark');
    const aboutBG = "url('../images/space-"+theme+".webp') fixed no-repeat 100% 100%/ cover;";
    const aboutBGMobile = "url('../images/space-"+theme+".webp') no-repeat center/ cover;";
    function aboutBGdm() {
        return noHover
        ? aboutBGMobile
        : aboutBG
    }
    const aboutSectionBG = aboutBGdm();

  /* ===== Parallax background provider ===== */
  const parallax_shadow = useColorModeValue('black', 'white');
  const parallax_theme = useColorModeValue('Night', 'Day');
  const parallax_theme_night = 'Night';
  const parallax_theme_day = 'Day';
  const BGURLAndreMelo = "AndreMelo.webp') no-repeat center/ contain;";
  const BGURL1 = "url('../images/parallax/";
  const BGURL2 = ".webp') no-repeat center/ cover";
  const BGURL3 = "-h992px.webp') no-repeat center/ cover";
  const BGURL4 = "-h992px-crop.webp') no-repeat center/ cover";
  const BGURL5 = "-h2100px-crop.webp') no-repeat center/ cover";

  function screenDetectBGProvider() {
    return noHover
    ? (portrait
      ? (under992 ? BGURL4 : BGURL5)
      : (under992 ? BGURL3 : BGURL2))
    : (under992 ? BGURL3 : BGURL2)
  }

  /* const AndreMeloURL = BGURL1+BGURLAndreMelo; */
  const SkyBGURL = BGURL1+parallax_theme_night+"Sky"+screenDetectBGProvider();
  const SkyDayBGURL = BGURL1+parallax_theme_day+"Sky"+screenDetectBGProvider();
  const AlmadaBGURL = BGURL1+parallax_theme_night+"Almada"+screenDetectBGProvider();
  const AlmadaDayBGURL = BGURL1+parallax_theme_day+"Almada"+screenDetectBGProvider();
  const TejoBGURL = BGURL1+parallax_theme_night+"Tejo"+screenDetectBGProvider();
  const TejoDayBGURL = BGURL1+parallax_theme_day+"Tejo"+screenDetectBGProvider();
  const LX1BGURL = BGURL1+parallax_theme_night+"LX3"+screenDetectBGProvider();
  const LX1DayBGURL = BGURL1+parallax_theme_day+"LX3"+screenDetectBGProvider();
  const LX2BGURL = BGURL1+parallax_theme_night+"LX2"+screenDetectBGProvider();
  const LX2DayBGURL = BGURL1+parallax_theme_day+"LX2"+screenDetectBGProvider();
  const LX3BGURL = BGURL1+parallax_theme_night+"LX1"+screenDetectBGProvider();
  const LX3DayBGURL = BGURL1+parallax_theme_day+"LX1"+screenDetectBGProvider();
  const LX3Shadow = "5px -10px 35px 50px "+'black'/* parallax_shadow *//* '#000000' */+", inset 0px -30px 15px -15px "+'black'/* parallax_shadow *//* '#000000' */;
  /* const Pillars = "url('../images/parallax/"+"concrete-pillar-fence"+".webp') no-repeat bottom/ contain" */

  
  function hoverORtap() {
      return noHover
      ? "(Tap me)"
      : "(Hover me)"
    }
  const hoverORtapPJS = hoverORtap();

  function andreMeloYInit() {
    return noHover
    ? (portrait
      ? (under768 ? "42vh" : "41vh")
      : (under768 ? (hUnder376 ? "33vh" : "33vh") : "30.5vh"))
    : (under768 ? "50vh" : "38vh")
  }

  /* if (under768 == true){
    document.getElementById("aD2").innerHTML = "Creative<br>Developer<br>&amp;";
  }; */
  /* function lettersYInit() {
    return noHover
    ? (portrait
      ? (under768 ? "48vh" : "48.5vh")
      : (under768 ? (hUnder376 ? "48.5vh" : "48vh") : "48.5vh"))
    : (under768 ? "50vh" : "50vh")
  }
  function lettersYLiftoff() {
    return noHover
    ? (portrait
      ? (under768 ? "2vh" : "2vh")
      : (under768 ? "4vh" : "2vh"))
    : (under768 ? "5vh" : "4vh")
  }
  function lettersYDrop() {
    return noHover
    ? (portrait
      ? (under768 ? "45vh" : "45vh")
      : (under768 ? "45vh" : "45vh"))
    : (under768 ? "50vh" : "70vh")
  }
  function lettersYRise() {
    return noHover
    ? (portrait
      ? (under768 ? "16vh" : "20vh")
      : (under768 ? (hUnder376 ? "20vh" : "0vh") : "20vh"))
    : (under768 ? "18.5vh" : "18.5vh")
  } */



  /* gsap.registerPlugin(ScrollTrigger, ScrollSmoother)
  let currentScrollPosSS = 0;
  let smoother = ScrollSmoother.create({
    wrapper: "#smooth-wrapper",
    content: "#smooth-content",
    smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
    effects: true, // looks for data-speed and data-lag attributes on elements
    smoothTouch: false, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    // normalizeScroll: true
    onUpdate: (self) => {
      //console.log("progress", self.progress);
      let currentScrollPosSS = self.progress;
      console.log("progress", currentScrollPosSS);
    },
  }); */
  let currentScrollPosSS = 0;

/* ===== GSAP React ===== */
  useGSAP(
    () => {      

      /* ScrollTrigger.normalizeScroll(true);
      ScrollTrigger.config({ ignoreMobileResize: true }); */

      let matchMedia = gsap.matchMedia();
      matchMedia.add("(hover: none)", () => {

        // this setup code only runs when its a touch device with no Hover function
        //console.log("mobile")

        smoother.current = ScrollSmoother.create({
          wrapper: "#smooth-wrapper",
          content: "#smooth-content",
          smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
          effects: true, // looks for data-speed and data-lag attributes on elements
          smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
          /* normalizeScroll: true, */
          ignoreMobileResize: true,
          onUpdate: (self) => {
            //console.log("progress", self.progress);
          },
        });

        return () => { // optional
          // custom cleanup code here (runs when it STOPS matching)
          //console.log("desktop")
        };
      });

      let target = document.querySelectorAll(".imagesWallImages img");
      //let skewSetter = gsap.quickTo(target, "skewY"); // fast
      //let skew = gsap.quickTo(target, "skew"); // fast
      let clamp = gsap.utils.clamp(-10, 10); // don't let the skew go beyond 20 degrees.

      gsap.set(target, {transformPerspective:800})

      smoother.current = ScrollSmoother.create({
        wrapper: "#smooth-wrapper",
        content: "#smooth-content",
        smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        //normalizeScroll: true,
        //ignoreMobileResize: true,
        onUpdate: (self) => {
          //console.log("progress", self.progress);
          //console.log(clamp(self.getVelocity() / -50));
          //console.log(self.getVelocity() / -50);
          //skewSetter(clamp(self.getVelocity() / -50));
          //gsap.set(target, {perspective:"100px"});
          let value =  clamp(self.getVelocity() / -50);
                      gsap.to(target, {rotationX: value});
          //gsap.to(target, {rotationY: value});
        },
        onStop: () => gsap.to(target, {rotationX: 0})//skewSetter(0)
      });

      //window.smoother = smoother.current


      // Snap to section when close enough (30%)
      const sections = gsap.utils.toArray(".snapSection");

      sections.forEach((section, index, ignoreSections) => {
        //const snapSection = gsap.timeline({paused:true})
          //window.smoother.scrollTo(section);
          //.to(smoother.current, {scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.current.offset(section)), duration: 0.5,})
        //let offset = smoother.current.offset(section, "top top");

        const sectionsSnap = gsap.timeline({
          scrollTrigger: {
            trigger: section,
            start: 'top 15%',
            //end: 'top top',
            scrub: false,
            //preventOverlaps:true,
            markers:false,
            invalidateOnRefresh:true,
            //fastScrollEnd: 3000,
            //wrapper: ".scrollMain",
            onEnter:(self)=> {
              if (self.getVelocity() < 3000 && mobileGecko() == false) {
                //This was the best solution as only a restart immediatly after play will not produce jumps
                //snapSection.play();
                //snapSection.restart();
                smoother.current.scrollTo(section, true);
                //gsap.to(window, { duration: 0.5, scrollTo: { y: section }, ease: "power2.inOut" });
                //gsap.to(smoother.current, {scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.current.offset(section)), duration: 0.5,})
                //console.log("Snapped to "+section.id);
                //gsap.to(smoother.current, { scrollTop: offset, duration: 1, });
              } else{
              };
            },
          }
        })
      });

      /* gsap.to(sections, {
        scrollTrigger: {
          //trigger:'#about-top',
          //start:'top 30%',
          markers:true,
          snap: {
            snapTo: 1 / (sections.length - 1),
            duration: {min: 0.2, max: 0.8},
            onStart: (self) => {console.log("onStart");},
            onInterrupt: (self) => {console.log("onInterrupt");},
            onComplete: (self) => {console.log("onComplete");},
          },
          //wrapper: "#smooth-content",
        }
      }); */

      /* let panels = gsap.utils.toArray(".snapSection"),
        snaps = [], // will store just the starting scroll value of each of the panels
        panelTriggers = panels.map((panel, i) => {
          return ScrollTrigger.create({
            trigger: panel,
            start: "top top",
            onRefresh: (self) => (
              snaps[i] = self.start
              ),
            onUpdate: self => console.log("progress", self.progress),
            markers: {startColor: "white", endColor: "red"},
          });
        });

      ScrollTrigger.create({
        // you can add a trigger and start/end values if you'd like to limit the snapping to only part of the page. 
        snap(progress, self, direction) {
          let totalDistance = self.end - self.start,
              snapProgress = snaps.map((v) => v / totalDistance);
          return ScrollTrigger.snapDirectional(snapProgress)(progress, self.direction);
        }
      }); */

      
      /* const start = smoother.current.scrollTrigger.addEventListener("scrollStart", () => console.log("scrolling started!"));
      const end = smoother.current.scrollTrigger.addEventListener("scrollEnd", () => console.log("scrolling ended!")); */


      /* ===== Blur Plugin ===== */
      (function() {
        const blurProperty = gsap.utils.checkPrefix("filter"),
              blurExp = /blur\((.+)?px\)/,
              getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];
      
        gsap.registerPlugin({
          name: "blur",
          get(target) {
            return +(getBlurMatch(target)[1]) || 0;
          },
          init(target, endValue) {
            let data = this,
                filter = gsap.getProperty(target, blurProperty),
                endBlur = "blur(" + endValue + "px)",
                match = getBlurMatch(target)[0],
                index;
            if (filter === "none") {
              filter = "";
            }
            if (match) {
              index = filter.indexOf(match);
              endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
            } else {
              endValue = filter + endBlur;
              filter += filter ? " blur(0px)" : "blur(0px)";
            }
            data.target = target; 
            data.interp = gsap.utils.interpolate(filter, endValue); 
          },
          render(progress, data) {
            data.target.style[blurProperty] = data.interp(progress);
          }
        });
      })();



      /* == Initial settings == */
      gsap.set(".andremeloWrapper", {translateY:andreMeloYInit});


      function setAndreMeloHomeOpacityBlink() {
        return isBlink
        ? gsap.set(".andremelo", {color: "rgb(0, 0, 0, 0.25)"})
        : gsap.set(".andremelo", {color: "rgb(0, 0, 0, 0.125)"})
      }
      setAndreMeloHomeOpacityBlink();

      function setAndreMeloHomeOpacityGecko() {
        return isGecko
        ? gsap.set(".andremelo", {color: "rgb(0, 0, 0, 0.25)"})
        : null
      }
      setAndreMeloHomeOpacityGecko();

      let parallaxLayersNight = gsap.utils.toArray(".parallaxLayer");
      let parallaxLayersDay = gsap.utils.toArray(".parallaxLayerDay");
      let currentParallaxLayer = "Night";

      parallaxLayersDay.forEach((layer, index) => {
        gsap.set(layer, {autoAlpha:0});
      });
      /* gsap.set('.KnockUpStreamHome', {scale:0.25 ,translateY: "50vh", yPercent:-50,
      translateX: "0vw", xPercent:-63, transformOrigin:"50% 50%", opacity:0.75, mixBlendMode: "screen"}) */
      //saturation, color, hard-light, color-dodge, lighten, overlay, screen, multiply

      /* ===== Parallax Night Day change ===== */
      const parallaxToggle = document.getElementById('themeToggle');
      parallaxToggle.addEventListener('change', function() {
        let parallaxLayersNight = gsap.utils.toArray(".parallaxLayer");
        let parallaxLayersDay = gsap.utils.toArray(".parallaxLayerDay");
        if (this.checked) {
          // Checkbox is checked // Night to Day
            parallaxLayersNight.forEach((nightLayer, index) => {
              gsap.to(nightLayer, {autoAlpha:0, duration:0.5, ease:"slow(0.5,0.7,false)"});
            });
            parallaxLayersDay.forEach((dayLayer, index) => {
              gsap.to(dayLayer, {autoAlpha:1, duration:0.5, ease:"power2.out"});
            });
            document.getElementById("smallPlane").classList.remove("planeByNight");
        } else {
          // Checkbox is unchecked // Day to Night
          parallaxLayersNight.forEach((nightLayer, index) => {
            gsap.to(nightLayer, {autoAlpha:1, duration:0.5, ease:"power2.out"});
          });
          parallaxLayersDay.forEach((dayLayer, index) => {
            gsap.to(dayLayer, {autoAlpha:0, duration:0.5, ease:"power2.out"});
          });
          document.getElementById("smallPlane").classList.add("planeByNight");
        }
      });


      /* ===== Plane Anim ===== */
      //gsap.set("#smallPlaneWithBannerSVG", {scale:0.2})
      /* const feTurbulenceInOut = gsap.timeline({ repeat: -1 })
        .to("feTurbulence", {
          duration:8,
          ease:"none",
          attr:{
            baseFrequency:0.002
          }
        })
        .to("feTurbulence", {
          duration:8,
          ease:"none",
          attr:{
            baseFrequency:0.001
          }
        }) */

      /* let randomTop = gsap.utils.random(50, 10, 5, true);
      const smallPlaneTop = () => {
        gsap.set("#smallPlaneWithBannerSVG", {top:(randomTop()+"vh")}, 0)
        //console.log(randomTop()+"vh")
      }
      setInterval(smallPlaneTop, 40000);  */


      let isTracking = false;
      let mouseMoveHandler = null;

      function trackMouseY() {
        const scrollPosition = window.scrollY;

        if (scrollPosition < window.innerHeight && !isTracking) {
          isTracking = true;

          mouseMoveHandler = function(event) {
            const mouseYVh = (event.clientY / window.innerHeight) * 100;
            /* gsap.to("#smallPlaneWithBannerSVG", {top: (mouseYVh+"vh")}, 0); */
            if (mouseYVh < 52) {
                gsap.to("#smallPlaneWithBannerSVG", {
                duration: 0.75,
                translateY: event.pageY,
                ease: "none",
                overwrite: "auto",
              });
            }

            //console.log('Mouse Y position in vh:', mouseYVh);
          };

          window.addEventListener('mousemove', mouseMoveHandler);
        }

        window.addEventListener('scroll', function() {
          const newScrollPosition = window.scrollY;
          if (newScrollPosition >= window.innerHeight) {
            window.removeEventListener('mousemove', mouseMoveHandler);
            isTracking = false;
          } else if (newScrollPosition < window.innerHeight && !isTracking) {
            isTracking = true;
            window.addEventListener('mousemove', mouseMoveHandler);
          }
        });
      }
      trackMouseY();


      gsap.set("#smallPlaneWithBannerSVG", {yPercent: -50});

      /* window.addEventListener("mousemove", e => {
        gsap.to("#smallPlaneWithBannerSVG", {
          duration: 0.75,
          translateY: e.pageY,
          ease: "none",
          overwrite: "auto",
        });
      }); */


      const planeAnim = gsap.timeline({ paused: true })
        .fromTo("#smallPlaneWithBannerSVG", {translateX:"100vw", xPercent:0}, {translateX:"0vw", xPercent:-105, ease:"linear", repeat:"-1", duration:40},)
        //.to("#smallPlaneWithBannerSVG", {duration:10, repeat:-1, ease: CustomWiggle.create("myWiggle", {wiggles:15}), translateY: -3}, 0)
        .to("feTurbulence", {
          duration:8, repeat:-1,
          ease:"none",
          attr:{ baseFrequency:0.002 } }, 0)
        //.add(feTurbulenceInOut)
        .to("feDisplacementMap", {
          duration:8, repeat:-1,
          ease:"none",
          attr:{ scale:200 } }, 0)

      planeAnim.play()





/* ===== KnockUpStreamHome Animation ===== */
      /* const KUStl = gsap.timeline({paused:true, repeat:-1})
        .fromTo('.KnockUpStreamHome', {
          scale:0.25, translateY: "75vh", yPercent:-50, translateX:"-70vw", opacity:0.75, zIndex:4,
          ease: "power2.inOut"},// mixBlendMode: "screen",
          {scale:0.25, translateY: "65vh", translateX:"70vw", duration: 3, zIndex:4,}
        )
        .to('.KnockUpStreamHome', { rotation:"+=1280", duration: 3, ease: "linear", }, 0
        )
        .fromTo('.KnockUpStreamHome', {
          scale:0.125, translateY: "48vh", yPercent:-50, translateX:"70vw", opacity:0.6, zIndex:3, ease: "power2.inOut"},
          {scale:0.125, translateY: "53vh", translateX:"-70vw", duration: 6, zIndex:3, }, "+=2")
        .to('.KnockUpStreamHome', { rotation:"-=2560", duration: 6, ease: "linear", }, "<"
        )
        .fromTo('.KnockUpStreamHome', {
          scale:0.1, translateY: "43.75vh", yPercent:-50, translateX:"-70vw", opacity:0.5, zIndex:1,
          ease: "power2.inOut"},// mixBlendMode: "screen",
          {scale:0.1, translateY: "42.75vh", translateX:"70vw", duration: 8, zIndex:1,}, "+=0.5")
        .to('.KnockUpStreamHome', { rotation:"+=3700", duration: 8, ease: "linear", }, "<"
        )
  
        KUStl.play(); */



      /* matchMedia.add("(orientation: portrait)", () => {
        // this setup code only runs when its a touch device with no Hover function
        console.log("mobile")

        return () => { // optional
          // custom cleanup code here (runs when it STOPS matching)
          console.log("desktop")
        };
      }); */

      /* = Individual letters fly in animation = */
      /* ScrollTrigger.create({
        trigger:'.nameLettersWrapper',
        start:'top top',
        end:'+=100.5% top',
        scrub:false,
        markers:false,
        pin:true,
        pinSpacing:false,
        onLeave: ({progress, direction, isActive}) => {
        },
        onEnterBack:() => {
        }
      })

      const lettersYDropPosition = ["39.5vw", "41.5vw", "43.5vw", "45.5vw", "48vw", "55vw", "60vw", "63.5vw", "67vw"]
      const lettersFinalLeftPosition = ["11.2vw", "21.15vw", "31.15vw", "39.85vw", "47.5vw", "62.7vw", "74.2vw", "81.4vw", "88.9vw"]
      const returnDestination = document.querySelector(".nameLettersWrapper");
      const splitAndremelo = gsap.utils.toArray('.nameLetters');
      splitAndremelo.forEach((letter, index) => {
        gsap.set(letter, {
          top:0,
          left:0,
          transformPerspective:800,
          translateY: lettersYInit,
          yPercent: -50,
          translateX: gsap.utils.random(0, 100)+"vw",
          xPercent: -50,
          rotationX:0
        })
        const a1mFlyIn = gsap.timeline({paused:true})
          a1mFlyIn.to(letter, {
              translateY: lettersYLiftoff,
              translateX: gsap.utils.random(40, 60)+"vw",
              ease: "sine.out",
              rotationX:-90,
              fontSize:"10vw",
              duration:0.8,
              delay:(index/8),
            }, 0)
          a1mFlyIn.set(".pin-spacer", {zIndex:150})
          a1mFlyIn.to(letter, {
            translateX: lettersYDropPosition[index],
            translateY: "75vh",
            ease: "power1.inOut",
            duration:1,})
          a1mFlyIn.to(letter,{rotationX:-180, ease: "power1.inOut", duration:0.2}, "<")
          a1mFlyIn.to(letter, {color:color, duration:0.75, ease: "power1.in"}, "<")
          a1mFlyIn.to(letter,{rotationX:0, ease: "power1.out", duration:0.4}, "<0.7")
          a1mFlyIn.to(letter, {translateX: lettersFinalLeftPosition[index], translateY: "50vh", ease: "sine.in", duration:0.5}, "<0.1")
          a1mFlyIn.to(letter,{fontSize:"17vw", ease: "expo.in", duration:0.5}, "<")

        ScrollTrigger.create({
          trigger:'.scrollParallax',
          start:'top top',
          end:'bottom top',
          scrub:1,
          markers:false,
          animation:a1mFlyIn,
          onLeave: ({progress, direction, isActive}) => {
            document.getElementById('andremeloWrapper').appendChild(letter);
            let cloneLetter = letter.cloneNode(true);
            cloneLetter.classList.add("letterClone");
            document.getElementById('andremeloWrapper').appendChild(cloneLetter);
            gsap.set(".letterClone", {color:color, zIndex:160});
            gsap.to(".letterClone", {opacity:0, duration:2, ease: "expo.out", delay:(index/12)});
            setTimeout(() => { document.querySelector('.letterClone').remove() }, 3000);
          },
          onEnterBack:() => {
            returnDestination.appendChild(letter)
          }
        })
      }) */

/* == Contact Section == */

      /* == GSAP Forms == */
      const formsGroup = gsap.utils.toArray("#contact-forms");
      formsGroup.forEach((form, index) => {
        gsap.fromTo(form, {
          autoAlpha:0,
          x: "-5vw",
        },
        {
          autoAlpha:1,
          x: "0vw",
          ease: "back.out",
          duration: 0.25,
          scrollTrigger: {
            trigger: form,
            start: 'top 95%',
            end: 'top 95%',
            markers:false,
            toggleActions: 'play play reverse reverse',
          },
        });
      });

      /* == GSAP Socials == */
      gsap.fromTo("#socials", {
        autoAlpha:0,
        x: "-5vw",
      },
      {
        autoAlpha:1,
        x: "0vw",
        ease: "back.out",
        duration: 0.2,
        stagger: 0.25,
        scrollTrigger: {
          trigger: "#socialicons",
          start: 'top 95%',
          markers:false,
          toggleActions: 'play none none reverse',
        },
      });

      /* function killAllRestart{
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
        init()
      } */


/* GSDevTools.create(); */

    },
    { dependencies: [color, noHover, portrait, under768, isGecko, /* lettersYInit, lettersYLiftoff, lettersYDrop, lettersYRise */], revertOnUpdate: true, scope: main }
  );

  /* function init() {
    gsap.set(".smooth-wrapper", {autoAlpha:1})
    //console.log(currentScrollPosSS)
  } */
  /* const AndreMeloSVG = () => (
    <svg x="0px" y="0px" viewBox="0 0 2073.2 400" style="enable-background:new 0 0 2073.2 400;">
      <g>
        <path id="A" d="M173.2,342.4l-10.8-54h-66l-10.4,54H0L81.2,64h98.4l80.8,278.4H173.2z M108,230.8h42.8l-21.6-109.2L108,230.8z"/>
        <path id="n" d="M449,137.6c10.3,11.2,15.4,26.9,15.4,47.2v157.6h-79.2V199.2c0-8-1-13.5-3-16.4c-2-2.9-5-4.4-9-4.4
          c-8.8,0-17.7,7.1-26.8,21.2v142.8h-79.2V128.8h69.2l5.6,22c9.9-10.4,19.9-18,30.2-22.8c10.3-4.8,21.8-7.2,34.6-7.2
          C424.7,120.8,438.7,126.4,449,137.6z"/>
        <path id="d" d="M702.8,44.8v297.6h-70.4l-2.8-22c-15.2,20-35.3,30-60.4,30c-26.9,0-47.2-10.3-60.8-30.8
          c-13.6-20.5-20.4-48.9-20.4-85.2c0-21.9,3.7-41.4,11.2-58.6c7.5-17.2,17.9-30.7,31.4-40.4c13.5-9.7,29-14.6,46.6-14.6
          c18.4,0,33.9,5.6,46.4,16.8V36.8L702.8,44.8z M623.6,274.8v-85.6c-3.7-4-7.5-7-11.2-9c-3.7-2-7.9-3-12.4-3
          c-9.3,0-16.8,4.6-22.4,13.8s-8.4,23.9-8.4,44.2c0,23.5,2.3,39.1,7,46.8c4.7,7.7,11.3,11.6,19.8,11.6
          C607.2,293.6,616.4,287.3,623.6,274.8z"/>
        <path id="r" d="M887.6,125.6L875.2,202c-7.2-2.1-13.7-3.2-19.6-3.2c-11.7,0-20.5,3.9-26.2,11.6c-5.7,7.7-10.3,19.3-13.8,34.8v97.2
          h-79.2V128.8h69.2l6.4,40c4.5-14.1,11.7-25.5,21.6-34c9.9-8.5,20.7-12.8,32.4-12.8C872.9,122,880.1,123.2,887.6,125.6z"/>
        <path id="e" d="M1088,258H958.4c2.1,14.4,6.6,24.1,13.4,29c6.8,4.9,16.6,7.4,29.4,7.4c7.5,0,15.1-1.5,22.8-4.6
          c7.7-3.1,16.3-7.8,25.6-14.2l32,43.2c-25.9,21.1-55.2,31.6-88,31.6c-37.9,0-66.5-10.5-86-31.4c-19.5-20.9-29.2-48.6-29.2-83
          c0-21.3,4.1-40.7,12.2-58.2c8.1-17.5,20.2-31.3,36.2-41.6c16-10.3,35.3-15.4,58-15.4c21.1,0,39.5,4.4,55.2,13.2
          c15.7,8.8,27.9,21.6,36.6,38.4c8.7,16.8,13,36.9,13,60.4C1089.6,239.5,1089.1,247.9,1088,258z M1012.8,0l29.6,57.6L942,97.2
          l-21.2-39.6L1012.8,0z M1011.6,210.4c-0.3-12.5-2.2-22.3-5.8-29.2c-3.6-6.9-10.1-10.4-19.4-10.4c-8.8,0-15.4,3.1-19.8,9.2
          c-4.4,6.1-7.3,17.2-8.6,33.2h53.6V210.4z"/>
        <path id="M" d="M1492.8,342.4h-81.2l-2-98.8c-0.3-7.7-0.4-19.3-0.4-34.8c0-27.2,1.1-53.5,3.2-78.8l-32.8,184.4h-78L1266.4,130
          c3.5,37.1,5.2,68.8,5.2,95.2v18l-2,99.2h-81.2l20-278.4H1310l32,180.4L1370.8,64h102L1492.8,342.4z"/>
        <path id="e_00000044176535413342476920000013043640122234757271_" d="M1715.2,258h-129.6c2.1,14.4,6.6,24.1,13.4,29
          c6.8,4.9,16.6,7.4,29.4,7.4c7.5,0,15.1-1.5,22.8-4.6c7.7-3.1,16.3-7.8,25.6-14.2l32,43.2c-25.9,21.1-55.2,31.6-88,31.6
          c-37.9,0-66.5-10.5-86-31.4c-19.5-20.9-29.2-48.6-29.2-83c0-21.3,4.1-40.7,12.2-58.2c8.1-17.5,20.2-31.3,36.2-41.6
          c16-10.3,35.3-15.4,58-15.4c21.1,0,39.5,4.4,55.2,13.2c15.7,8.8,27.9,21.6,36.6,38.4c8.7,16.8,13,36.9,13,60.4
          C1716.8,239.5,1716.2,247.9,1715.2,258z M1638.8,210.4c-0.3-12.5-2.2-22.3-5.8-29.2c-3.6-6.9-10.1-10.4-19.4-10.4
          c-8.8,0-15.4,3.1-19.8,9.2c-4.4,6.1-7.3,17.2-8.6,33.2h53.6V210.4z"/>
        <path id="l" d="M1753.8,332.2c-11.6-12.1-17.4-29.3-17.4-51.4v-236l79.2-8v240.8c0,7.2,3.2,10.8,9.6,10.8c2.9,0,5.7-0.5,8.4-1.6
          l14,56.4c-12.5,4.8-27.2,7.2-44,7.2C1782,350.4,1765.4,344.3,1753.8,332.2z"/>
        <path id="o" d="M2037,150.8c19.6,20,29.4,48.4,29.4,85.2c0,22.9-4.5,43.1-13.4,60.4c-8.9,17.3-21.7,30.7-38.2,40
          c-16.5,9.3-36,14-58.4,14c-34.1,0-61-10-80.6-30c-19.6-20-29.4-48.4-29.4-85.2c0-22.9,4.5-43.1,13.4-60.4
          c8.9-17.3,21.7-30.7,38.2-40c16.5-9.3,36-14,58.4-14C1990.5,120.8,2017.4,130.8,2037,150.8z M1934.4,191.2
          c-4.5,9.1-6.8,23.7-6.8,44c0,21.1,2.3,36.1,6.8,45c4.5,8.9,11.9,13.4,22,13.4c10.1,0,17.5-4.5,22-13.6c4.5-9.1,6.8-23.7,6.8-44
          c0-21.1-2.3-36.1-6.8-45c-4.5-8.9-11.9-13.4-22-13.4C1946.2,177.6,1938.9,182.1,1934.4,191.2z"/>
      </g>
    </svg>
  ); */

  /* const ASVG = () => (
    <svg viewBox="0 0 260.4 278.4" width="6vw" height="auto" style={{ fill: "#c70002", preserveAspectRatio:"xMidYMid meet" }}>
      <path id="A" data-name="A" d="m173.2,278.4l-10.8-54h-66l-10.4,54H0L81.2,0h98.4l80.8,278.4h-87.2Zm-65.2-111.6h42.8l-21.6-109.2-21.2,109.2Z"></path>
  </svg>
  ); */
  
  /* window.addEventListener("load", init) */
  /* window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  } */


  return (
    <>
      <Nav smoother={smoother} color={color} bgColor={bgColor}/>
      <div id="smooth-wrapper" ref={main}>
        <div id="smooth-content">
        {/* <div className="wrapper menuSections" id="wrapper-section" ref={wrapper}> */}

          <div className="theme_switch">
            <ToggleButton
              isDark={!isDark}
              invertedIconLogic
              onChange={() => {}}
              onClick={() => {setDark((prev) => !prev)}}
            />
          </div>

          {/* <div class="scrollParallax"></div> */}
          {/* <div class="scrollAndreMelo"></div> */}

          <section className="parallaxMain menuSections" id="home-section"/* background={aboutSectionBG} */>

            <Box className="version-container">[Beware, website in closed Beta Testing! v0.9-β.87 ]</Box>
            <Box className="parallaxLayer sky" data-speed="0.1" background={SkyBGURL} /* backgroundPosition="50% 100%" */></Box>
            <Box className="parallaxLayerDay sky" data-speed="0.1" background={SkyDayBGURL}></Box>
            <div className="andremeloWrapper" data-speed="0" /* background={AndreMeloURL} */><div className="andremelo">André Melo</div></div>
            {/* <div className="andremelo2">André Melo</div> */}
            {/* <div className="nameLettersWrapper"> <div className="nameLetters">A</div> <div className="nameLetters">n</div> <div className="nameLetters">d</div> <div className="nameLetters">r</div> <div className="nameLetters">é</div> <div className="nameLetters">M</div> <div className="nameLetters">e</div> <div className="nameLetters">l</div> <div className="nameLetters">o</div> </div> */}
            <Box className="parallaxLayer almada" data-speed="0.2" background={AlmadaBGURL}></Box>
            <Box className="parallaxLayerDay almada" data-speed="0.2" background={AlmadaDayBGURL}></Box>
            <Box className="parallaxLayer river" data-speed="0.4" background={TejoBGURL}></Box>
            <Box className="parallaxLayerDay river" data-speed="0.4" background={TejoDayBGURL}></Box>

            <Box id="smallPlane" className="smallPlaneWithBanner planeByNight" data-speed="0.00001"><SmallPlaneWithBanner2/></Box>
            
            <Box className="parallaxLayer lx1" data-speed="0.5" background={LX1BGURL}></Box>
            <Box className="parallaxLayerDay lx1" data-speed="0.5" background={LX1DayBGURL}></Box>
            <Box className="parallaxLayer lx2" data-speed="0.725" background={LX2BGURL}></Box>
            <Box className="parallaxLayerDay lx2" data-speed="0.725" background={LX2DayBGURL}></Box>
            <Box className="parallaxLayer lx3" data-speed="0" background={LX3BGURL} boxShadow={LX3Shadow}/>
            <Box className="parallaxLayerDay lx3" data-speed="0" background={LX3DayBGURL} boxShadow={LX3Shadow}/>

            {/* <Box className="KnockUpStreamHome"><KnockUpStreamSVG2/></Box> */}

          </section>

          {/* <div class="scrollMain"></div> */}
          <About hoverORtapPJS={hoverORtapPJS} color={color} bgColor={bgColor} colorName={colorName} aboutSectionBG={aboutSectionBG} noHover={noHover} portrait={portrait} under768={under768} isGecko={isGecko}/>
          <Projects smoother={smoother} hoverORtapPJS={hoverORtapPJS} color={color} bgColor={bgColor}/>
          <Contact color={color} colorName={colorName} bgColor={bgColor} socialBorderColor={socialBorderColor}/>
        {/* <Lab /> */}
          <Footer color={color} bgColor={bgColor}/>
        {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Home;