import React, {useEffect/* , useRef, useState */} from "react";
import "./Contact.css";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import FullScreenSection from "./FullScreenSection";
import useSubmit from "../hooks/useSubmit";
import {useAlertContext} from "../context/alertContext";


const Contact = (props) => {
    const {isLoading, response, submit} = useSubmit();
    const { onOpen } = useAlertContext();
    
    /* console.log(bgColor); */

/*     const windowWidth = useRef(window.innerWidth);
    const [currentWindowWidth, setCurrentWindowWidth] = useState({
			windowWidth
		})
    console.log('width: ', windowWidth.current);
    console.log(windowWidth.current, "px"); */

    /* function WidthComponent() { */
      /* const [dimensions, setDimensions] = React.useState({     
        height: window.innerHeight,    
        width: window.innerWidth    
      })
      React.useEffect(() => {    
        function handleResize() {    
          setDimensions({    
            height: window.innerHeight,    
            width: window.innerWidth    
          })  
        }    
        window.addEventListener('resize', handleResize)    
        return _ => {    
          window.removeEventListener('resize', handleResize)
        }
      }) */
      /* return <div>Rendered at {dimensions.width} x {dimensions.height}</div>
    } */

    const formik = useFormik({
        initialValues: {
            firstName: "",
            email: "",
            type: "hireMe",
            comment: "",
        },
        onSubmit: (values) => {
        submit('https://formsubmit.co/andre.d.melo.1@gmail.com', values);
        },
        validationSchema: Yup.object ({
            firstName: Yup.string().required("Required"),
            email: Yup.string().email("Invalid email address").required("Required"),
            comment: Yup.string().min(25, "Must be 25 characters at minimum").required("Required"),
        }),
    });

    useEffect (() => {
        if (response) {
            onOpen (response.type, response.message);
            if (response.type === 'success') {
                formik.resetForm();
            }
        }
    }, [response]);

  return (
    <FullScreenSection
      /* isDarkBackground */
      backgroundColor='#000000' //{props.bgColor}
      color='#ffffff' //{props.color}
      /* isDarkBackground={bgColor} */
      justifyContent="center"
      alignItems="center"
      /* py={20} */
      spacing={8}
      zIndex="100"
      className="snapSection"
      id="contact-section"
      width="100%"
      minHeight={{ base: "100vh", md: "100vh"}}
      position='relative'
    >
      <Box
        position="absolute"
        bg="transparent"
        width="100%"
        top="-5.01vw"
        zIndex="100"
      >
        <Box
          height="5vw"
          width="100%"
          backgroundColor="black"
          clipPath="polygon(0% 50%, 0 100%, 100% 100%, 100% 50%, 53% 90%, 50% 100%, 47% 90%)"
          webkit-clip-path="0% 50%, 0 100%, 100% 100%, 100% 50%, 53% 90%, 50% 100%, 47% 90%)"
        />
        <Box height="3px" width="100%" marginTop="-1px" backgroundColor="black"/>
      </Box>
      <VStack maxWidth={{ base: "100%", md: "600px", lg: "768px" }} w="100%"/* {dimensions.width + "px"} */ p={8} /* alignItems="flex-start" */>
        <Heading
            as="text"
            marginBottom={{ base: "0.5vh", md: "7vh", lg: "7vh" }}
            fontSize={{ base: "4rem", sm: "4.5rem", md: "5rem", lg: "6rem", xl: "6rem" }}
            fontFamily='Paytone One'
            fontWeight='400'
            textAlign="center"
            >
            <div class="gsap-reveal-t">Contact</div>
        </Heading>
        <Box
          px={6}
          /* paddingTop={{ base: "6", md: "4", lg: "6" }} */
          rounded="md" w="100%"
        >
          <form target="_blank" action="https://formsubmit.co/a148e2733bc8e224670bfaed393dff52" method="POST"/* onSubmit={formik.handleSubmit} */>
            <VStack id="gsap-reveal-forms" spacing={{ base: "0.65rem", md: "1.5vh", lg: "2vh" }}/* {4} */ paddingBottom={{ base: "0", md: "4", lg: "8" }}>
                <FormControl isInvalid={!!formik.errors.firstName && formik.touched.firstName}>
                    <FormLabel id="contact-forms" htmlFor="firstName">Name</FormLabel>
                    <div id="contact-forms"><Input
                    id="firstName"
                    name="firstName"
                    borderColor='#ffffff' //{props.color}
                    {...formik.getFieldProps("firstName")}
                   /></div>
                   <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                    <FormLabel id="contact-forms" htmlFor="email">Email Address</FormLabel>
                    <div id="contact-forms"><Input
                    id="email"
                    name="email"
                    type="email"
                    borderColor='#ffffff' //{props.color}
                    {...formik.getFieldProps("email")}
                    /></div>
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel id="contact-forms" htmlFor="type">Type of enquiry</FormLabel>
                  <div id="contact-forms"><Select
                  id="type"
                  name="type"
                  borderColor='#ffffff' //{props.color}
                  >
                    <option value="sayHello">Say hello!</option>
                    <option value="hireMe">Freelance project proposal</option>
                    {/* <option value="openSource">Open source consultancy session</option> */}
                    <option value="other">Other</option>
                  </Select></div>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.comment && formik.touched.comment}>
                  <FormLabel id="contact-forms" htmlFor="comment">Your message</FormLabel>
                  <div id="contact-forms"><Textarea
                    id="comment"
                    name="comment"
                    height={{ base: "5rem", md: "10rem", lg: "225" }}/* {125} */
                    borderColor='#ffffff' //{props.color}
                    {...formik.getFieldProps("comment")}
                  /></div>
                  <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
                </FormControl>
                <div id="contact-forms"><Button id="contact-forms" type="submit" colorScheme="whatsapp" width="full">
                Submit
              </Button></div>
            </VStack>
          </form>
          
          <div id="socialicons">
            <ul>
                <li id="socials">
                    <Text as="a" href="https://github.com/andre1melo/" title="Github" target="_blank" rel="noopener" border="3px solid #fff" borderColor='#ffffff33'>
                        <img src={"images/icons/github-mark-"+'white'/* props.colorName */+".svg"} data-src={"images/icons/github-mark-"+'white'/* props.colorName */+".svg"} alt="github" /* class=" lazyloaded" */></img>
                    </Text>
                </li>
                <li id="socials">
                    <Text as="a" href="https://www.linkedin.com/in/andre1melo/" title="LinkedIn" target="_blank" rel="noopener" border="3px solid #fff" borderColor='#ffffff33'>
                        <img src={"images/icons/linkedin-"+'white'/* props.colorName */+".svg"} data-src={"images/icons/linkedin-"+'white'/* props.colorName */+".svg"} alt="linkedin" /* className="lazyloaded" */></img>
                    </Text>
                </li>
            </ul>{/* 
            <ul>
                <li>
                    <a href="https://stackoverflow.com/users/22890044/andre1melo/" title="stack overflow" target="_blank" rel="noopener">
                        <img src="images/icons/stackoverflow.svg" data-src="images/icons/stackoverflow.svg" alt="" className=" lazyloaded"></img>
                    </a>
                </li>
            </ul> */}
          </div>

        </Box>
      </VStack>
    </FullScreenSection>
  );
};

export default Contact;