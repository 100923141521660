const SmallPlaneWithBanner = () => (
  <svg viewBox="0 0 11617.54 1626.84" class="smallPlaneWithBannerSVG" id="smallPlaneWithBannerSVG" style={{ preserveAspectRatio:"xMidYMid meet" }}>
    {<defs>
      <radialGradient id="Super_Soft_Black_Vignette" dataName="Super Soft Black Vignette" cx="5689.61" cy="1135.56" fx="5689.61" fy="1135.56" r="174.21" gradientTransform="translate(-12682.45 -1505.62) scale(2.86 2.13)" gradientUnits="userSpaceOnUse">
        <stop offset=".57" stop-color="#130c0e"/>
        <stop offset=".8" stop-color="#130c0e"/>
        <stop offset="1" stop-color="#130c0e"/>
      </radialGradient>
      <radialGradient id="Super_Soft_Black_Vignette-2" dataName="Super Soft Black Vignette" cx="3141.16" cy="915.39" fx="3141.16" fy="915.39" r="193.49" gradientTransform="matrix(1,0,0,1,0,0)" xlinkHref="#Super_Soft_Black_Vignette"/>
      <filter id="warp-filter-0" color-interpolation-filters="sRGB" x="-50%" y="-50%" width="200%" height="200%">
        <feComponentTransfer>
          <feFuncR type="table" tableValues="1 0.5"></feFuncR>
        </feComponentTransfer>
        <feMerge result="a">
          <feMergeNode></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
        <feTurbulence type="fractalNoise" baseFrequency="0.001" numOctaves="1" result="warp"></feTurbulence>
        <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="120" in="a" in2="warp"></feDisplacementMap>
      </filter>
    </defs>}
    {
      <g id="plane" dataName="plane">

        <g id="Small_planeG"><image id="Small_plane" x="0" y="200" width="4000" height="1485" xlinkHref="../images/small_plane.webp"/></g>

        <polygon id="harness" points="4783.73 1410.91 4372.79 915.39 4783.73 419.87 4783.73 1410.91" fill="none" strokelinecap="round" strokelinejoin="round" strokeWidth="50" stroke="rgb(0, 0, 0)" style={{stroke: "rgb(0, 0, 0)", strokeWidth: "20px"}}/>
        <line id="line" x1="4372.79" y1="915.39" x2="3829.52" y2="915.39" fill="none" strokelinecap="round" strokelinejoin="round"  strokeWidth="50" stroke="rgb(0, 0, 0)" style={{stroke: "rgb(0, 0, 0)", strokeWidth: "20px"}}/>

        <g id="banner" style={{filter: "url('#warp-filter-0')"}} transform="matrix(1, 0, 0, 1, 960, 0)"><defs></defs>            
          <polygon id="bannerBG" points="10515.96 1410.91 3823.73 1410.91 3823.73 419.87 10515.96 419.87 10285.96 919.01 10515.96 1410.91" fill="#fff" opacity=".9" strokewidth="0"></polygon>
          <g>
            <path d="m4358.45,1278.39c-42,0-84.67-5.17-128-15.5-43.34-10.33-80-24.16-110-41.5l36-163c38.66,20.67,74.83,34.17,108.5,40.5,33.66,6.34,64.83,9.5,93.5,9.5,26.66,0,46.83-4.33,60.5-13,13.66-8.66,20.5-20.33,20.5-35,0-13.33-7.67-23.83-23-31.5-15.34-7.66-35.17-14.83-59.5-21.5-24.34-6.66-50.17-14.5-77.5-23.5-27.34-9-53.17-21-77.5-36-24.34-15-44.17-35-59.5-60-15.34-25-23-56.5-23-94.5,0-48,11.66-89.5,35-124.5,23.33-35,56.66-62,100-81,43.33-19,94.33-28.5,153-28.5,39.33,0,77.83,5.17,115.5,15.5,37.66,10.34,67.83,24.17,90.5,41.5l-34,147c-34-14-64.5-24.16-91.5-30.5-27-6.33-52.5-9.5-76.5-9.5-28,0-49.34,4-64,12-14.67,8-22,18.67-22,32s7.66,23,23,31c15.33,8,35,15.67,59,23,24,7.34,49.66,15.67,77,25,27.33,9.34,53,21.67,77,37,24,15.34,43.66,35.34,59,60,15.33,24.67,23,55.67,23,93,0,77.34-25.17,137-75.5,179-50.34,42-120.17,63-209.5,63Z" strokewidth="0"></path>
            <path d="m4958.43,1270.39c-56,0-104.34-11-145-33-40.67-22-72-52.16-94-90.5-22-38.33-33-82.5-33-132.5s11.33-93.66,34-133c22.66-39.33,54.5-70.33,95.5-93,41-22.66,88.5-34,142.5-34,64.66,0,115.66,13.34,153,40l-22,136c-16.67-7.33-32.34-12.5-47-15.5-14.67-3-28.67-4.5-42-4.5-32.67,0-58.5,9.17-77.5,27.5-19,18.34-28.5,43.17-28.5,74.5s9.33,55.5,28,74.5c18.66,19,43.33,28.5,74,28.5,26.66,0,58-5.66,94-17l19,140c-22,10.67-44.84,18.67-68.5,24-23.67,5.33-51.17,8-82.5,8Z" strokewidth="0"></path>
            <path d="m5186.42,1262.39v-501h208v125c13.33-45.33,34.16-78.66,62.5-100,28.33-21.33,62.16-32,101.5-32l25,177c-16-2-31.67-3-47-3-92.67,0-139,38-139,114v220h-211Z" strokewidth="0"></path>
            <path d="m5881.41,1270.39c-56.67,0-106.67-11-150-33-43.34-22-77.17-52.5-101.5-91.5-24.34-39-36.5-83.83-36.5-134.5s12.5-97.16,37.5-135.5c25-38.33,59.16-68.16,102.5-89.5,43.33-21.33,93-32,149-32s107.66,10.84,151,32.5c43.33,21.67,77,51.67,101,90,24,38.34,36,83.17,36,134.5s-12.17,96.5-36.5,135.5c-24.34,39-58.17,69.34-101.5,91-43.34,21.67-93.67,32.5-151,32.5Zm2-150c27.33,0,49.83-10.16,67.5-30.5,17.66-20.33,26.5-46.5,26.5-78.5s-8.67-58.16-26-78.5c-17.34-20.33-39.34-30.5-66-30.5-28.67,0-51.84,10.17-69.5,30.5-17.67,20.34-26.5,46.5-26.5,78.5s8.83,58.17,26.5,78.5c17.66,20.34,40.16,30.5,67.5,30.5Z" strokewidth="0"></path>
            <path d="m6442.41,1270.39c-47.34,0-85.84-8-115.5-24-29.67-16-51.17-37.66-64.5-65-13.34-27.33-20-58-20-92v-506l211-31v508c0,15.34,5,27.17,15,35.5,10,8.34,23,12.5,39,12.5s32-4,48-12l15,139c-16,11.34-36.34,20-61,26-24.67,6-47,9-67,9Z" strokewidth="0"></path>
            <path d="m6817.41,1270.39c-47.34,0-85.84-8-115.5-24-29.67-16-51.17-37.66-64.5-65-13.34-27.33-20-58-20-92v-506l211-31v508c0,15.34,5,27.17,15,35.5,10,8.34,23,12.5,39,12.5s32-4,48-12l15,139c-16,11.34-36.34,20-61,26-24.67,6-47,9-67,9Z" strokewidth="0"></path>
            <path d="m7187.41,1262.39v-688h239c100,0,181.5,14.34,244.5,43,63,28.67,109.33,68.34,139,119,29.66,50.67,44.5,109.67,44.5,177,0,110-36.17,195.67-108.5,257-72.34,61.34-182.17,92-329.5,92h-229Zm221-175h64c53.33,0,93-16.16,119-48.5,26-32.33,39-74.83,39-127.5,0-48.66-12.5-87.83-37.5-117.5-25-29.66-65.17-44.5-120.5-44.5h-64v338Z" strokewidth="0"></path>
            <path d="m8193.39,1270.39c-56.67,0-106.67-11-150-33-43.34-22-77.17-52.5-101.5-91.5-24.34-39-36.5-83.83-36.5-134.5s12.5-97.16,37.5-135.5c25-38.33,59.16-68.16,102.5-89.5,43.33-21.33,93-32,149-32s107.66,10.84,151,32.5c43.33,21.67,77,51.67,101,90,24,38.34,36,83.17,36,134.5s-12.17,96.5-36.5,135.5c-24.34,39-58.17,69.34-101.5,91-43.34,21.67-93.67,32.5-151,32.5Zm2-150c27.33,0,49.83-10.16,67.5-30.5,17.66-20.33,26.5-46.5,26.5-78.5s-8.67-58.16-26-78.5c-17.34-20.33-39.34-30.5-66-30.5-28.67,0-51.84,10.17-69.5,30.5-17.67,20.34-26.5,46.5-26.5,78.5s8.83,58.17,26.5,78.5c17.66,20.34,40.16,30.5,67.5,30.5Z" strokewidth="0"></path>
            <path d="m8643.41,1262.39l-130-501h214l67,315,79-315h172l80,323,67-323h171l-121,501h-239l-66-255-63,255h-231Z" strokewidth="0"></path>
            <path d="m9421.39,1262.39v-501h208v109c15.33-37.33,38.16-66,68.5-86,30.33-20,66.5-30,108.5-30,65.33,0,116,22.5,152,67.5s54,108.5,54,190.5v250h-211v-261c0-59.33-24-89-72-89-28.67,0-52,10.5-70,31.5s-27,49.17-27,84.5v234h-211Z" strokewidth="0"></path>
          </g>
          <rect x="3823.73" y="419.87" width="40.11" height="991.04" fill="#ec4f48" strokewidth="0"></rect>
          <rect x="3823.73" y="419.87" width="34.29" height="991.04" fill="#c1272d" strokewidth="0"></rect>
          <rect x="3823.73" y="419.87" width="13.7" height="991.04" fill="#a0272d" strokewidth="0"></rect>
          <polygon points="10515.96 1410.91 3823.73 1410.91 3823.73 419.87 10515.96 419.87 10285.96 919.01 10515.96 1410.91" fill="none" stroke="#414042" strokemiterlimit="10" strokewidth="2"></polygon>
        </g>
      </g>
    }
  </svg>
);

export default SmallPlaneWithBanner;