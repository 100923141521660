import React from 'react';
import './Loader.css'; // Assuming you will create a CSS file for styling

const Loader = ({ progress }) => {
  const progressInt = parseInt(progress, 10);

  return (
    <div className="loader-container">

      {/* <div class="loader">
        <span></span>
        <span></span>
        <span></span>
      </div> */}

      {/* <div className="progress-bar-container"><div className="progress-bar" style={{ width: `${progress}%` }}></div></div> */}
      <div className="progress-container">
        <div className="progress-text">{progressInt} %</div>
        <svg className="progress-circle" width="100%" height="100%">
          <defs>
            <filter id="inset-shadow">
              <feFlood flood-color="#080808"/>
              <feComposite operator="out" in2="SourceAlpha"/>
              <feGaussianBlur stdDeviation="4"/>
              <feComposite operator="in" in2="SourceGraphic"/>
              <feBlend mode="multiply" in="SourceGraphic" />
            </filter>
          </defs>
          <circle
            className="progress-circle-bg"
            cx="50%"
            cy="50%"
            r="15vw" // Set the radius to 30vw for a circle size of 30vw
            fill="transparent"
            stroke="#272727"
            strokeWidth="15"
            zIndex="1"
          ></circle>
          <circle
            className="progress-circle-fill"
            cx="50%"
            cy="50%"
            r="15vw" // Set the radius to 30vw for a circle size of 30vw
            fill="transparent"
            stroke="#b00000"
            strokeWidth="15"
            filter="url(#inset-shadow)"
            strokeDasharray={`${94.5}vw`} // 283 * (30vw / 100) = 94.5vw
            strokeDashoffset={`${94.5 - (94.5 * progressInt) / 100}vw`} // Adjusted for 30vw size
            transform="rotate(-90deg 50% 50%)"
            zIndex="2"
          ></circle>
        </svg>
      </div>
    </div>
  );
};

export default Loader;