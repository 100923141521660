import React from "react";
import styles from "./Toggle.styles.module.css";

const defaultOptions = {
  invertedIconLogic: false
};

const ToggleButton = ({
  isDark,
  onChange,
  onClick,
  invertedIconLogic = defaultOptions.invertedIconLogic
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  
  <label
    className={styles.container}
    title={isDark ? "Activate Day mode" : "Activate Night mode"}
    aria-label={isDark ? "Activate Day mode" : "Activate Night mode"}
  >
    <input
      id="themeToggle"
      type="checkbox"
      defaultChecked={invertedIconLogic ? !isDark : isDark}
      onChange={onChange && onClick}
    />
    <div />
    <p>(Toggle)</p> 
  </label>
);

export default ToggleButton;


/* <p>{isDark ? "(Toggle)" : "Dark mode"}</p>*/
