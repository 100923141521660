import "./ProjectStack.css";
import { useState, useRef } from 'react';
import {
    Flex,
    Text,
    HStack,
    Box,
    Image,
    Stack,
    VStack,
    StackDivider,
    useMediaQuery,
    Center,
    Link,
    Popover,
    PopoverTrigger,
    Button,
    PopoverContent,
    PopoverHeader,
    PopoverCloseButton,
    PopoverArrow,
    PopoverBody,
    background
} from "@chakra-ui/react";
import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

function ProjectStack(props) {

  const project = useRef();

  /* ===== Flash Notice ===== */
  const handleFlashNotice = (flashNotice) => {
    if (flashNotice === undefined) {
        /* console.log("undefined"); */
        return null;
    }
    else {
        /* console.log(techStack); */
        return <Popover isLazy zIndex="500" closeOnBlur={true} placement='top-start'>
        <PopoverTrigger>
            <Button size={{base: "sm", md: "sm", lg: "md", xl: "md"}} colorScheme="red" bg='#480000' fontSize={{base: "0.95rem", md: "0.95rem", lg: "0.8rem"}} marginTop="5%" color="white">Flash project notice</Button>
        </PopoverTrigger>
        <PopoverContent width={{base: "23rem", md: "25rem", lg: "23rem"}} backgroundColor="black" textAlign="center">
            {/* <PopoverHeader fontWeight='semibold'>Popover placement</PopoverHeader> */}
            <PopoverArrow backgroundColor="black"/>
            {/* <PopoverCloseButton /> */}
            <PopoverBody color="white" fontSize={{base: "0.8rem", md: "0.9rem", lg: "0.8rem"}}textAlign="start">
            {flashNotice}
            </PopoverBody>
        </PopoverContent>
    </Popover>
    }
  }

  useGSAP(
    () => {

    },
    { dependencies: [props.color, props.noHover, props.portrait, props.under768], revertOnUpdate: true, scope: project }
  );

  return (
    <div className="projectWrapper">
      <Box className="projectItem" ref={project} /* bg={props.bgColor} */>
        
        <h1>{props.title}</h1>
          <h1>{props.title}</h1>
        <h2>{props.servicesCard}</h2>
          <h2>{props.servicesCard}</h2>
        <h3>{props.year}</h3>
          <h3>{props.year}</h3>
        <Flex className="projectMain flexRow2Col">
          <Flex className="projectDescription flexCol" /* hidden */>
            <Flex className="flexRow2ColDesktopTablet">
              <Box className="projectDescriptionOverview">
                {/* <h4>Project Overview</h4> */}
                <p>{props.description}</p>
                {handleFlashNotice(props.popover)}
              </Box>
              <Flex className="projectDescriptionDetails flexCol2RowDesktopTablet">
                <Flex className="projectDescriptionDetailsSections flexCol">
                  <h4>Services</h4>
                  <p>{props.services}</p>
                </Flex>
                <Flex className="projectDescriptionDetailsSections flexCol">
                  <h4>Year</h4>
                  <p>{props.year}</p>
                </Flex>
                <Flex className="projectDescriptionDetailsSections flexCol">
                  <h4>Client</h4>
                  <p>{props.client}</p>
                </Flex>
              </Flex>
            </Flex>
            <Flex className="projectLink flexCol">
              <Link className="linkButton" href={props.link} isExternal>
                  <svg class="svgLinkArrowIcon-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
                    <path d="m438.6,169.41c12.5,12.5,12.5,32.79,0,45.28l-160,159.94c-12.5,12.5-32.8,12.5-45.3,0s-12.5-32.79,0-45.28l105.5-105.36H32c-17.7,0-32-14.29-32-31.99s14.3-31.99,32-31.99h306.81l-105.4-105.36c-12.5-12.5-12.5-32.79,0-45.28s32.8-12.5,45.3,0l160,159.94-.1.1h0Z" stroke-width="0"/>
                  </svg>
                  <svg class="svgLinkArrowIcon-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
                    <path d="m438.6,169.41c12.5,12.5,12.5,32.79,0,45.28l-160,159.94c-12.5,12.5-32.8,12.5-45.3,0s-12.5-32.79,0-45.28l105.5-105.36H32c-17.7,0-32-14.29-32-31.99s14.3-31.99,32-31.99h306.81l-105.4-105.36c-12.5-12.5-12.5-32.79,0-45.28s32.8-12.5,45.3,0l160,159.94-.1.1h0Z" stroke-width="0"/>
                  </svg>
                {/* {props.linktext} */}<Text>Launch Project</Text>
              </Link>
            </Flex>
          </Flex>
          <Image id="projectImage" src={props.image} srcSet={props.imageset} alt="Project Screenshot"></Image>
        </Flex>
        <button class="backButton" id="projectBackButton">
          <svg class="svgBackArrowIcon-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
            <path d="m9.38,169.41c-12.5,12.5-12.5,32.79,0,45.28l160,159.94c12.5,12.5,32.8,12.5,45.3,0s12.5-32.79,0-45.28l-105.5-105.36h306.8c17.7,0,32-14.29,32-31.99s-14.3-31.99-32-31.99H109.17l105.4-105.36c12.5-12.5,12.5-32.79,0-45.28s-32.8-12.5-45.3,0L9.27,169.31l.1.1Z" stroke-width="0"/>
          </svg>
          <span class="backButtonText">Close</span>
          <svg class="svgBackArrowIcon-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
            <path d="m9.38,169.41c-12.5,12.5-12.5,32.79,0,45.28l160,159.94c12.5,12.5,32.8,12.5,45.3,0s12.5-32.79,0-45.28l-105.5-105.36h306.8c17.7,0,32-14.29,32-31.99s-14.3-31.99-32-31.99H109.17l105.4-105.36c12.5-12.5,12.5-32.79,0-45.28s-32.8-12.5-45.3,0L9.27,169.31l.1.1Z" stroke-width="0"/>
          </svg>
        </button>
      </Box>
    </div>
    );
};

export default ProjectStack;