import React from "react";
import { useState, useRef } from 'react';
//import { useDeviceOrientation } from './useDeviceOrientation';
import "./Projects.css";
import { Box, VStack, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import ProjectStack from "./ProjectStack";
import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { GSDevTools } from "gsap/GSDevTools";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const htmlLogo = "../images/logos/html5-original-wordmark-white.svg"
const cssLogo = "../images/logos/css3-original-wordmark-white.svg"
const react = "../images/logos/react-original-wordmark.webp"
const reactNative = "../images/logos/reactNative.webp"
const flashLogo = "../images/logos/flash.webp"
const foundationLogo = "../images/logos/foundation-original-wordmark-white.webp"
const premiereLogo = "../images/logos/premierepro.svg"
const aftereffectsLogo = "../images/logos/aftereffects.svg"
const sqliteLogo = "../images/logos/sqlite-white.webp"

const projectsList = [
  {
    id: "RyeBakeryApp",
    title: "Rye Bakery",
    servicesCard: "App Design, App Development",
    services: ["App Design",<br/>,"App Development"],
    description: ["An App was developed for this Bali based bakery (fictional) so that their clients could easily login, browse the current menu and subscribe to E-mail notifications on special offers and newsletters.",
    <br/>, <br/>,
    "Built using React Native on Expo Go with AsyncStorage for the users preferences and SQLite Database to populate, query and filter the menu items."],
    image: "../images/rye_bakery_app/RyeBakeryAPP.webp",
    imageset: "../images/rye_bakery_app/RyeBakeryAPP-480px.webp 480w, ../images/rye_bakery_app/RyeBakeryAPP-768px.webp 768w, ../images/rye_bakery_app/RyeBakeryAPP-992px.webp 992w",
    year: "2023",
    client: "Rye Bakery (fict.)",
    icon: "../images/rye_bakery_app/RyeBakeryIcon.webp",
    html: htmlLogo,
    css: cssLogo,
    reactNative: reactNative,
    sqlite: sqliteLogo,
    link: "https://snack.expo.dev/@andre1melo/rye-bakery?platform=ios",
    linktext: "snack.expo.dev/Rye-Bakery",
  },
  {
    id: "littleLemonWebsite",
    title: "Little Lemon",
    servicesCard: "Web Development",
    services: "Web Development",
    description: ["A Website with table reservation and online order for a ficticious family owned Mediterranean restaurant, focused on traditional recipes served with a modern twist.",
    <br/>, <br/>,
    "Built using React, HTML and CSS.",
    <br/>,
    "In the table reservation page JSON data of the available booking times is fetched from the API and then the (client-side validated) form is submitted to the API."],
    image: "../images/little_lemon_website/Little_Lemon_Website_1s.webp",
    imageset: "../images/little_lemon_website/Little_Lemon_Website_1s-480px.webp 480w, ../images/little_lemon_website/Little_Lemon_Website_1s-768px.webp 768w, ../images/little_lemon_website/Little_Lemon_Website_1s-992px.webp 992w",
    year: "2023",
    client: "Little Lemon (fict.)",
    icon: "../images/little_lemon_website/LittleLemonIcon.webp",
    html: htmlLogo,
    css: cssLogo,
    react: react,
    link: "https://little-lemon-andre1melo.netlify.app",
    linktext: "little-lemon-andre1melo",
  },
  {
    id: "swc2015",
    title: "SWC 2015",
    servicesCard: "Video Editing",
    services: "Video Editing",
    description: ["A quick video edit I made for my sport (Streetboard) community.",
    <br/>,
    "Non-stop edit of the Streetboard World Championships 2015 at the D.O.W. in Dudelange, Luxembourg."],
    image: "../images/manguekyo-studio/ms.webp",
    imageset: "../images/manguekyo-studio/SWC-2015-480px.webp 480w, ../images/manguekyo-studio/SWC-2015-768px.webp 768w, ../images/manguekyo-studio/SWC-2015-992px.webp 992w, ../images/manguekyo-studio/SWC-2015-1280px.webp 1280w",
    year: "2015",
    client: "Streetboard community",
    icon: "../images/manguekyo-studio/SWC-2015-icon.webp",
    premiere: premiereLogo,
    aftereffects: aftereffectsLogo,
    link: "https://vimeo.com/manage/videos/134969223",
    linktext: "vimeo.com/SWC-2015",
  },
  {
    id: "manguekyostudio",
    title: "Manguekyo Studio", //ŠŠŚŜṠｓ
    servicesCard: "Concept, Web Design, Web Development",
    services: ["Concept",<br/>,"Web Design",<br/>,"Web Development"],
    description: ["This was Manguekyo Studio's website until 2013.",
    <br/>,
    "A responsive design (pretty new at the time!) HTML5 and CSS3 on top of a Foundation framework solution.",
    <br/>,
    "Some of the inner projects are not available.",
    <br/>, <br/>,
    "Flash projects might not play correctly on some devices"],
    image: "../images/manguekyo-studio/ms.webp",
    imageset: "../images/manguekyo-studio/ms-480px.webp 480w, ../images/manguekyo-studio/ms-768px.webp 768w, ../images/manguekyo-studio/ms-992px.webp 992w, ../images/manguekyo-studio/ms-1280px.webp 1280w",
    year: "2012",
    client: "Self",
    icon: "../images/manguekyo-studio/ms-icon.webp",
    html: htmlLogo,
    css: cssLogo,
    foundation: foundationLogo,
    link: "https://manguekyo-studio.netlify.app/",
    linktext: "manguekyo-studio",
  },
  {
    id: "uchiha",
    title: "Uchiha Itachi",
    servicesCard: "Concept, Web Design, Web Development",
    services: ["Concept",<br/>,"Web Design",<br/>,"Web Development"],
    description: ["A Flash solution was created for a fansite of the Uchiha Clan that engages the visitor in a cinematic and interactive depiction of the origins and life of the fictional character Uchiha Itachi from the popular anime series Naruto®."],
    popover:  ["Flash projects might not play correctly on some devices.",
    <br/>,
    "Sound (an integral part of the experience) might be disabled by default in your system.",
    <br/>,
    "Navigation was not designed for touch devices so it can be challenging or quite impossible"],
    image: "../images/manguekyo-studio/uchiha-v2.webp",
    imageset: "../images/manguekyo-studio/uchiha-v2-480px.webp 480w, ../images/manguekyo-studio/uchiha-v2-768px.webp 768w, ../images/manguekyo-studio/uchiha-v2-992px.webp 992w",
    year: "2006",
    client: "Uchiha Clan fansite",
    icon: "../images/manguekyo-studio/uchiha_crest.webp",
    html: htmlLogo,
    css: cssLogo,
    flash: flashLogo,
    link: "https://manguekyo-studio.netlify.app/2006/uchiha/",
    linktext: "manguekyo-studio/uchiha",
  },
  /* {
    id: "AcademiaPatinsAgarra",
    title: "Agarra a Vida",
    services: "Web Design, Web Development",
    description: ["A|Park created a sports show that travels throughout the schools of Portugal.",
    <br/>, <br/>,
    "A simple Flash solution was developed for students, teachers and fans to obtain information of dates, visited schools and athletes present in the show.",<br/>,<br/>],
    popover:  ["Flash projects might not play correctly on some devices."],
    image: "../images/manguekyo-studio/apservicos.webp",
    imageset: "../images/manguekyo-studio/agarra-a-vida-480px.webp 480w, ../images/manguekyo-studio/agarra-a-vida-768px.webp 768w, ../images/manguekyo-studio/agarra-a-vida-992px.webp 992w, ../images/manguekyo-studio/agarra-a-vida-1280px.webp 1280w",
    year: "2005",
    client: "A|Park",
    icon: "../images/manguekyo-studio/agarra-a-vida-icon.webp",
    html: htmlLogo,
    css: cssLogo,
    flash: flashLogo,
    link: "https://manguekyo-studio.netlify.app/2005/tour_agarra_a_vida/index.html",
    linktext: "manguekyo-studio/agarra-a-vida",
  }, */
  {
    id: "AcademiaPatinsServices",
    title: "A|Park",
    servicesCard: "Concept, Web Design, Web Development",
    services: ["Concept",<br/>,"Web Design",<br/>,"Web Development"],
    description: ["In order to promote and increase their sales of skateparks and other services, A|Park (formerly Academia dos Patins) needed an interactive DVD that would transmit the high-energy and professionalism of the company and it's premium quality skatepark solutions.",
    <br/>,<br/>,
    "(Videos and slideshows are not available.)"],
    popover:  ["Flash projects might not play correctly on some devices."],
    image: "../images/manguekyo-studio/apservicos.webp",
    imageset: "../images/manguekyo-studio/apservicos-480px.webp 480w, ../images/manguekyo-studio/apservicos-768px.webp 768w, ../images/manguekyo-studio/apservicos-992px.webp 992w",
    year: "2004",
    client: "A|Park",
    icon: "../images/manguekyo-studio/apservicos_icon.webp",
    html: htmlLogo,
    css: cssLogo,
    flash: flashLogo,
    link: "https://manguekyo-studio.netlify.app/2004/academia_dos_patins_dvd/dvd",
    linktext: "manguekyo-studio/apark-dvd",
  },
  {
    id: "shodo",
    title: "Shodo",
    servicesCard: "Concept, Web Design, Web Development",
    services: ["Concept",<br/>,"Web Design",<br/>,"Web Development"],
    description: ["A project on Shodo (Japanese caligraphy art) about its history, techniques, materials and related subjects.",
    <br/>,
    "A Flash solution was developed with a bilingual Japanese and Portuguese navigational menu."],
    popover:  ["Flash projects might not play correctly on some devices.",
    <br/>,
    "Sound (an integral part of the experience) might be disabled by default in your system.",
    <br/>,
    "Navigation was not designed for touch devices so it can be challenging or impossible"],
    image: "../images/manguekyo-studio/shodo2s.webp",
    imageset: "../images/manguekyo-studio/shodo2s-480px.webp 480w, ../images/manguekyo-studio/shodo2s-768px.webp 768w, ../images/manguekyo-studio/shodo2s-992px.webp 992w",
    year: "2003",
    client: "etic_",
    icon: "../images/manguekyo-studio/sho.webp",
    html: htmlLogo,
    css: cssLogo,
    flash: flashLogo,
    link: "https://manguekyo-studio.netlify.app/2003/sho/",
    linktext: "manguekyo-studio/shodo",
  },
];

const Projects = (props) => {

  const projects = useRef();

  gsap.registerPlugin(
    ScrollTrigger,
    CustomEase,
    GSDevTools);

  useGSAP(
    () => {

      let backButtons = gsap.utils.toArray(".backButton");
      backButtons.forEach((button, index) => {
        //console.log(button.children);
        const backButtonHover = gsap.timeline({paused:true})
          .to(button, {width: "6rem", backgroundColor: "rgba(200, 0, 0, 0.85)", boxShadow: "0px 2px 10px 0px rgba(200, 0, 0, 1), inset 2px 2px 6px rgba(150, 0, 0, 1), inset -2px -2px 6px rgba(150, 0, 0, 1)", duration: 0.2, ease:"power1.out"}, 0)
          .to(button.children[0], {translateX:"-3.5rem", duration: 0.2, ease:"power1.inOut"}, 0)
          .to(button.children[1], {translateX:"-4.75rem", opacity:1, duration: 0.2, ease:"power1.inOut"}, 0)
          .to(button.children[2], {translateX:"-3.25rem", duration: 0.2, ease:"power1.inOut"}, 0)

        const backButtonDown = gsap.timeline({paused:true})
        .to(button, {backgroundColor: "rgba(200, 0, 0, 0.65)", boxShadow: "0px 1px 5px 0px rgba(200, 0, 0, 1), inset 4px 4px 12px rgba(96, 0, 0, 1), inset -4px -4px 12px rgba(96, 0, 0, 1)", translateY:"2%", duration: 0.125, ease:"power1.in"}, 0)
        .to(button.children[1], {translateX:"-5rem", duration: 0.2, ease:"power1.inOut"}, 0)
        .to(button.children[2], {translateX:"-3.5rem", duration: 0.2, ease:"power1.inOut"}, 0)

        button.addEventListener("mouseenter", () => backButtonHover.play());
        button.addEventListener("mouseleave", () => backButtonHover.reverse());
        button.addEventListener("mousedown", () => backButtonDown.play());
        button.addEventListener("mouseup", () => backButtonDown.reverse());
      });

      let linkButtons = gsap.utils.toArray(".linkButton");
      linkButtons.forEach((button, index) => {
        //console.log(button.children);
        gsap.set(button, {textDecoration: "none"});
        gsap.set(button.querySelectorAll(".svgLinkArrowIcon-1, .svgLinkArrowIcon-2"), {rotation:-45, transformOrigin:"50% 50%"})
        gsap.set(button.querySelectorAll(".svgLinkArrowIcon-2"), {translateX:"-300%", translateY:"300%"})
        const linkButtonHover = gsap.timeline({paused:true})
          .to(button, {backgroundColor: "rgba(200, 0, 0, 0.85)", boxShadow: "0 2px 10px 0 rgba(200, 0, 0, 1), inset 2px 2px 6px rgba(150, 0, 0, 1), inset -2px -2px 6px rgba(150, 0, 0, 1)", duration: 0.2, ease:"power1.in"}, 0)
          .to(button.querySelector(".svgLinkArrowIcon-1"), {translateX:"330%", translateY:"-300%", duration: 0.2, ease:"power1.out"}, 0)
          .to(button.querySelector(".svgLinkArrowIcon-2"), {translateX:"30%", translateY:"0%", duration: 0.2, ease:"power1.out"}, 0)
          //.to(button.children[0], {translateX:"350%", duration: 0.2, ease:"power1.out"}, 0)
          //.to(button.children[1], {translateX:"320%", duration: 0.2, ease:"power1.out"}, 0)
          .to(button.children[2], {translateX:"3%", duration: 0.2, ease:"power1.out"}, 0)
        
        const linkButtonDown = gsap.timeline({paused:true})
        .to(button, {backgroundColor: "rgba(200, 0, 0, 0.65)", boxShadow: "0px 1px 5px 0px rgba(200, 0, 0, 1), inset 4px 4px 12px rgba(96, 0, 0, 1), inset -4px -4px 12px rgba(96, 0, 0, 1)", /* border:"1px solid white", */ translateY:"2%", duration: 0.125, ease:"power1.in"}, 0)

        button.addEventListener("mouseenter", () => linkButtonHover.play());
        button.addEventListener("mouseleave", () => linkButtonHover.reverse());
        button.addEventListener("mousedown", () => linkButtonDown.play());
        button.addEventListener("mouseup", () => linkButtonDown.reverse());
      });


      let projectItems = gsap.utils.toArray(".projectWrapper");

      projectItems.forEach((project, index) => {
        /* ========== Initial set's ========== */
        gsap.set(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {autoAlpha: 0})
        gsap.set(project.querySelectorAll('.projectItem h2:nth-of-type(1)'), {autoAlpha: 0})
        /* const hideProperty = (target) => { target.hidden = true; console.log("hide"); }
        const showProperty = (target) => { target.hidden = false; console.log("show"); } */
        //console.log(project);
        
        //const boundingBox = project.querySelector('.projectDescription').getBoundingClientRect();
        //console.log(project.querySelector('.projectDescription').getBoundingClientRect().top);

        /* ========== open Project Landscape ========== */
        const openProjectLandscape = gsap.timeline({
          paused:true,
          onStart: ()=>{
            projectMouseEventListeners(false);
            resetStyles();
            gsap.set(project, {cursor: "default"});
            /* showProperty(project.children[0].children[6].children[0]) */
          },
          onUpdate: (self)=>{props.smoother.current.scrollTo(project, true, "top "+((window.innerHeight - ((window.innerHeight*100)/100)) / 2)+"px")},
          onComplete: ()=>{
            project.classList.add(".active");
            projectMouseEventListeners(false);
            resetStyles();
            projectOpenMouseEventListeners(false);
            projectCloseMouseEventListeners(true);
          },
          onReverseComplete: ()=>{
            project.classList.remove(".active");
            projectMouseEventListeners(true);
            projectCloseMouseEventListeners(false);
            projectOpenMouseEventListeners(true);
            gsap.set(project, {cursor: "pointer"});
          }
          })
          .set(project.querySelectorAll('.projectMain'), {z: 0}) //projectMain Z reset
          .to(project.querySelectorAll('.projectItem'), {width: "100vw", height: "100vh", margin: "0 0 2vw 0", duration: 0.25, ease: "slow(0.7,0.7,false)"}, 0)
          .to(project.querySelectorAll('.projectItem'), {outline: "0.5rem solid #480000", duration: 0.25})
          .to(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h2'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h3'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .fromTo(project.querySelectorAll('.projectItem h1:nth-of-type(2)'),
          {/* fontSize: "6vw", */ position: "absolute", maxWidth:"100%", translateX:"50%", left:"50%", xPercent:-100, textAlign:"start", },
          {fontSize: "5vw", position: "absolute", maxWidth:"35%", translateX:"0%", left:"0vw", xPercent:0, paddingLeft:"3%", duration: 0.45, ease:"power1.out"}, 0.25)
          .to(project.querySelectorAll('.projectItem h1'), {translateY: "30vh", yPercent:-100, duration: 0.25, ease:"power2.in"}, 0.35)
          .to(project.querySelectorAll('.projectMain img'),
            {padding: "0% 2.5% 0% 0%", maxWidth:"60%", height: "fit-content", maxHeight: "80%", translateY: "50vh", yPercent:-50, ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
            .fromTo(project.querySelectorAll('.projectMain img'),{objectFit:"cover"}, 
              {objectFit:"contain"}, 0.49)
          .fromTo(project.querySelectorAll('.projectDescription'),
            {flexBasis:"0%", padding: "0%", opacity: 0},
            {flexBasis:"40%", padding: "3%", translateY: "60vh", yPercent:-50, opacity: 1, duration: 0.5, ease:"power2.in",
              /* onComplete: function(){
                let projectDescriptionTop = project.querySelectorAll('.projectDescription').getBoundingClientRect().top;
              } */}, 0)
          .fromTo(project.querySelectorAll('.backButton'), {autoAlpha:0, left:"52%"}, {autoAlpha:1, left:"50%", duration: 0.25, ease:"power2.out"}, 0.35)
            

        /* ========== open Project Portrait Mobile ========== */
        const openProjectPortraitMobile = gsap.timeline({
          paused:true,
          onStart: ()=>{
            projectMouseEventListeners(false);
            resetStyles();
            gsap.set(project, {cursor: "default"});
            // showProperty(project.children[0].children[6].children[0])
          },
          onUpdate: (self)=>{
            props.smoother.current.scrollTo(project, true, "top "+((window.innerHeight - ((window.innerHeight*100)/100)) / 2)+"px");
          },
          onComplete: ()=>{
            project.classList.add(".active");
            projectMouseEventListeners(false);
            resetStyles();
            projectOpenMouseEventListeners(false);
            projectCloseMouseEventListeners(true);
            setTimeout(() => {ScrollTrigger.refresh()}, 50);
          },
          onReverseComplete: ()=>{
            project.classList.remove(".active");
            projectMouseEventListeners(true);
            projectCloseMouseEventListeners(false);
            projectOpenMouseEventListeners(true);
            gsap.set(project, {cursor: "pointer"});
            setTimeout(() => {ScrollTrigger.refresh()}, 50);
          }
          })
          .set(project.querySelectorAll('.projectMain'), {z: 0}) //projectMain Z reset
          //.set(project.querySelector('.projectItem'), {transformPerspective: 0})
          .to(project.querySelectorAll('.projectItem'), {width: "100vw", height: "fit-content", minHeight: "100lvh", margin: "0 0 2vw 0", duration: 0.25, ease: "slow(0.7,0.7,false)"}, 0)
          .fromTo(project.querySelectorAll('.projectDescription'),
            {flexBasis:"0%", padding: "0%", opacity: 0},
            {flex: "1 1 auto", padding: "7rem 3% 3% 3%", /* translateY: "60vh", yPercent:-50, */ opacity: 1, duration: 0.5, ease:"power2.in"}, 0)
          .fromTo(project.querySelectorAll('.projectMain img'),{objectFit:"cover"}, 
            {flex: "1", padding: "3%", width: "auto", height: "fit-content", maxHeight: "50%", objectFit:"contain", ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
            .to(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {opacity: 0, duration: 0.125}, 0)  // Hide
            .to(project.querySelectorAll('.projectItem h2'), {opacity: 0, duration: 0.125}, 0)  // Hide
            .to(project.querySelectorAll('.projectItem h3'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .fromTo(project.querySelectorAll('.projectItem'), {outline: "0rem solid #480000"}, {outline: "0.5rem solid #480000", duration: 0.25})
          .fromTo(project.querySelectorAll('.projectItem h1:nth-of-type(2)'),
          {/* fontSize: "6vw", */ position: "absolute", /* width:"max-content", */ left:"50%", xPercent:-100, textAlign:"start", },
          {fontSize: "3.75rem", position: "relative", translateY: "4rem", /* width:"100%", */ textAlign:"center", /* yPercent:-100, */ duration: 0.25, ease:"power2.in"}, 0.25)
          //.set(project.querySelectorAll('.projectDescription'),{flexFlow: "column wrap", alignContent: "flex-end"})
            .to(project.querySelectorAll('.projectDescription h4'),{fontSize: "5vw", paddingBottom: "1%", ease:"power2.out"},0.25)
            .to(project.querySelectorAll('.projectDescription p'),{fontSize: "4vw", ease:"power2.out"},0.25)
          .fromTo(project.querySelectorAll('.backButton'), {autoAlpha:0, left:"52%"}, {autoAlpha:1, left:"50%", duration: 0.25, ease:"power2.in"}, 0.5)

        /* ========== open Project Portrait Tablet ========== */
        const openProjectPortraitTablet = gsap.timeline({
          paused:true,
          onStart: ()=>{
            projectMouseEventListeners(false);
            resetStyles();
            gsap.set(project, {cursor: "default"});
            /* showProperty(project.children[0].children[6].children[0]) */
          },
          onUpdate: (self)=>{props.smoother.current.scrollTo(project, true, "top "+((window.innerHeight - ((window.innerHeight*100)/100)) / 2)+"px")},
          onComplete: ()=>{
            project.classList.add(".active");
            projectMouseEventListeners(false);
            resetStyles();
            projectOpenMouseEventListeners(false);
            projectCloseMouseEventListeners(true);
            setTimeout(() => {ScrollTrigger.refresh()}, 50);
          },
          onReverseComplete: ()=>{
            project.classList.remove(".active");
            projectMouseEventListeners(true);
            projectCloseMouseEventListeners(false);
            projectOpenMouseEventListeners(true);
            gsap.set(project, {cursor: "pointer"});
            setTimeout(() => {ScrollTrigger.refresh()}, 50);
          }
          })
          .set(project.querySelectorAll('.projectMain'), {z: 0}) //projectMain Z reset
          //.set(project.querySelector('.projectItem'), {transformPerspective: 0})
          .to(project.querySelectorAll('.projectItem'), {width: "100vw", height: "fit-content", minHeight: "100lvh", margin: "0 0 2vw 0", duration: 0.25, ease: "slow(0.7,0.7,false)"}, 0)
          .fromTo(project.querySelectorAll('.projectItem'), {outline: "0rem solid #480000"}, {outline: "0.5rem solid #480000", duration: 0.25})
          .to(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h2'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h3'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .fromTo(project.querySelectorAll('.projectItem h1:nth-of-type(2)'),
          {/* fontSize: "6vw", */ position: "absolute", /* width:"max-content", */ left:"50%", xPercent:-100, textAlign:"start", },
          {fontSize: "14vw", position: "relative", translateY: "4rem", /* width:"100%", */ textAlign:"center", /* yPercent:-100, */ duration: 0.25, ease:"power2.in"}, 0.25)
          .fromTo(project.querySelectorAll('.projectMain img'),{objectFit:"cover"}, 
            {flex: "1", padding: "3%", width: "auto", height: "fit-content", maxHeight: "50%", objectFit:"contain", ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          .fromTo(project.querySelectorAll('.projectDescription'),
            {flexBasis:"0%", padding: "0%", opacity: 0},
            {flex: "1 1 auto", padding: "10rem 3% 3% 3%%", /* translateY: "60vh", yPercent:-50, */ opacity: 1, duration: 0.5, ease:"power2.in"}, 0)
          .to(project.querySelectorAll('.projectDescription h4'),{fontSize: "1.25rem", paddingBottom: "1%", ease:"power2.out"},0.25)
          .to(project.querySelectorAll('.projectDescription p'),{fontSize: "1.1rem", ease:"power2.out"},0.25)
          .fromTo(project.querySelectorAll('.backButton'), {autoAlpha:0, left:"52%"}, {autoAlpha:1, left:"50%", duration: 0.25, ease:"power2.in"}, 0.5)

        /* project.addEventListener("click", () => {
          if (openProject.totalProgress() === 1){
            openProject.reverse();
            // hideProperty(project.children[0].children[6].children[0]);
          } else if (openProject.totalProgress() === 0){
            openProject.play();
          }
        }) */

        const playProject = () => {
          if (window.matchMedia("(orientation: portrait)").matches){
            if (window.matchMedia("(max-width: 767px)").matches){
              //console.log("portraitMobile");
              openProjectPortraitMobile.play()
            } else {
              //console.log("portraitTablet");
              openProjectPortraitTablet.play()
            }
          } else if (window.matchMedia("(orientation: landscape)").matches){
            //console.log("landscape");
            openProjectLandscape.play()
          }
        }
        const reverseProject = () => {
          if (window.matchMedia("(orientation: portrait)").matches){
            if (window.matchMedia("(max-width: 767px)").matches){
              //console.log("portraitMobile");
              openProjectPortraitMobile.reverse()
            } else {
              //console.log("portraitTablet");
              openProjectPortraitTablet.reverse()
            }
          } else if (window.matchMedia("(orientation: landscape)").matches){
            //console.log("landscape");
            openProjectLandscape.reverse()
          }
        }
        //const reverseProject = () => {openProjectLandscape.reverse()}

        const projectOpenMouseEventListeners = (active) => {
          if (active === false) {
            //console.log("Inactive");
            project.removeEventListener("click", playProject);
          } else if (active === true) {
            //console.log("Active");
            project.addEventListener("click", playProject);
          }
        }
        projectOpenMouseEventListeners(true);

        const projectCloseMouseEventListeners = (active, e) => {
          if (active === false) {
            project.querySelector('.backButton').removeEventListener("click", reverseProject);
          } else if (active === true) {
            project.querySelector('.backButton').addEventListener("click", reverseProject);
          }
        }
        projectCloseMouseEventListeners(false);

        /* if (project) {
          openProject.totalProgress() === 1
            ? openProject.reverse() && project.classList.remove(".active") || projectMouseEventListeners(true)
            : null;
        } else if (project) {
          openProject.totalProgress() === 0
            ? openProject.play() && project.classList.add(".active") || projectMouseEventListeners(false) || resetStyles()
            : null;
        } */

        /* ===== Desktop 3D mouse follow on each projects ===== */
        const motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");
        const noHoverMatchMedia = window.matchMedia("(hover: none)");
        //const project = document.querySelector(".projectItem");
        const THRESHOLD = 15;

        const hoverProjectAnim = gsap.timeline({paused:true})
          .set(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {autoAlpha: 1})
          .set(project.querySelectorAll('.projectItem h2:nth-of-type(1)'), {autoAlpha: 1})
          .to(project.querySelectorAll('.projectItem h1:nth-of-type(2)'), {z: 90, ease:"power4.in", duration:0.2})
          .to(project.querySelectorAll('.projectItem h2:nth-of-type(2)'), {z: 20, duration:0.2}, 0.1)
          .to(project.querySelectorAll('.projectItem h3:nth-of-type(2)'), {z: 20, duration:0.2}, 0.1)
          .to(project.querySelector('.projectMain'), {z: 15, duration:0.2}, 0.2)
          /* .to(project.children[0].children[3].children[1].children[1], {z: 40, duration:0.2}, "-=0.1")
          .to(project.children[0].children[6], {z: 30, duration:0.2}, "-=0.1") */

        function handleHover(e) {
          const { clientX, clientY, currentTarget } = e;
          const { clientWidth, clientHeight, offsetLeft, offsetTop } = currentTarget;
          let offsetTopRelativeToWindow = project.querySelector('.projectItem').getBoundingClientRect().top /* + window.scrollY */;
          //console.log(offsetTopRelativeToWindow);

          const horizontal = (clientX - offsetLeft) / clientWidth;
          const vertical = (clientY - offsetTopRelativeToWindow) / clientHeight;
          const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
          const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

          project.querySelector('.projectItem').style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
          //project.children[0].children[1].style.transform = `perspective(${clientWidth}px) scale3d(1, 1, 1)`;
          hoverProjectAnim.play();
        }

        function resetStyles(e) {
          project.querySelector('.projectItem').style.transform = `rotateX(0deg) rotateY(0deg)`;
            /* `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`; */
          hoverProjectAnim.reverse();
        }

        const projectMouseEventListeners = (active) => {
          if (/* !motionMatchMedia.matches && !noHoverMatchMedia.matches && */ active === false) {
            project.querySelector('.projectItem').removeEventListener("mousemove", handleHover);
            project.querySelector('.projectItem').removeEventListener("mouseleave", resetStyles);
          } else if (noHoverMatchMedia.matches) {
            project.querySelector('.projectItem').removeEventListener("mousemove", handleHover);
            project.querySelector('.projectItem').removeEventListener("mouseleave", resetStyles);
            resetStyles();
          } else if (!motionMatchMedia.matches && !noHoverMatchMedia.matches && active === true) {
            project.querySelector('.projectItem').addEventListener("mousemove", handleHover);
            project.querySelector('.projectItem').addEventListener("mouseleave", resetStyles);
          }
        }
        projectMouseEventListeners(true);


        /* ===== Mobile 3D scroll follow on each projects ===== */
        let matchMedia = gsap.matchMedia();
        matchMedia.add("(hover: none)", () => {
          // this setup code only runs when its a touch device with no Hover function
          //console.log("mobile")
          const mobile3DProjectsInAnim = gsap.timeline({
            scrollTrigger: {
              trigger: project,
              start: 'top bottom',
              end: 'top 70%',
              scrub: true,
              //preventOverlaps:true,
              markers:false,
              invalidateOnRefresh:true,
              //fastScrollEnd: 3000,
              //toggleActions: "play none reverse none",
              onUpdate:(self)=> {//console.log("progress:", self.progress)
              },
            }
          })
            .set(project.querySelector('.projectItem'), {transformOrigin:"50% 0%"/* , transformPerspective: 600 */})
            .fromTo(project.querySelector('.projectItem'), {scale: 0.5}, {scale: 1, ease:"power1.in"}, 0)
            .fromTo(project.querySelector('.projectMain img'), {scale: 1.75}, {scale: 1, 
              ease:"power2.in"}, 0)

          const mobile3DProjectsOutAnim = gsap.timeline({
            scrollTrigger: {
              trigger: project,
              start: 'bottom 15%',
              end: 'bottom top',
              scrub: true,
              //preventOverlaps:true,
              markers:false,
              invalidateOnRefresh:true,
              //fastScrollEnd: 3000,
              //toggleActions: "play none reverse none",
              onUpdate:(self)=> {//console.log("progress:", self.progress)
              },
              onEnter:(progress) => {
                //console.log(progress);
                //openProjectPortraitTablet.timeScale(-3);
                //openProjectPortraitTablet.reverse();
              }
            }
          })
            .set(project.querySelector('.projectItem'), {transformOrigin:"50% 100%"})
            .fromTo(project.querySelector('.projectItem'), {scale: 1}, {scale: 0.5, ease:"power1.out"}, 0)

          return () => { // optional
            // custom cleanup code here (runs when it STOPS matching)
            //console.log("desktop")
          };
        });

      })

      /* ===== Rejected 3D mouse follow on all projects ===== */
      /* gsap.set(".projectItem", {transformPerspective:"800"})

      let rotateXTo = gsap.quickSetter(".active", "rotateX", "deg"),
          rotateYTo = gsap.quickSetter(".active", "rotateY", "deg");

      window.addEventListener("mousemove", e => {
        let mouseX = e.clientX - window.innerWidth / 2;
        let mouseY = e.clientY - window.innerHeight / 2;
        let rotateValueX = gsap.utils.mapRange(-window.innerWidth / 2, window.innerWidth / 2, -10, 10, mouseX);
        let rotateValueY = gsap.utils.mapRange(-window.innerHeight / 2, window.innerHeight / 2, -10, 10, mouseY);
        let rotateValueXFinal = rotateValueX*2;
        let rotateValueYFinal = rotateValueY*-1.5;
        
        console.log('rotateValueX: ' + rotateValueXFinal + 'rotateValueY: ' + rotateValueYFinal);
        rotateXTo(rotateValueYFinal);
        rotateYTo(rotateValueXFinal);
      }); */

/* GSDevTools.create({animation: openProject}); */
    },
    { dependencies: [props.color, props.noHover, props.portrait, props.under768], revertOnUpdate: true, scope: projects }
  );

  return (
    <section id="projects-section" className="menuSections snapSection" ref={projects}>
      <VStack
          /* backgroundColor={props.bgColor}"rgb(96, 0, 0, 1)" */
          /* color={props.color}"white" */
          /* paddingTop="3%" */
          alignItems="center"
          spacing={0}
          zIndex="100"
          width="100%"
          /* height="fit-content" */
      >
        <VStack backgroundColor="rgb(96, 0, 0, 0.85)" width="100%" zIndex="102" /* marginBottom="4vh" */ minHeight="100vh" /* color={props.color} */ backdropFilter="blur(5px)" /* marginTop="5rem" */ /* boxShadow="0rem -0.5em 0.25rem 0.5rem rgb(96, 0, 0, 0.85)" */>
          <Box marginTop="2vh">
            <div /* class="gsap-reveal-t" */>
              <p aria-label="Projects Heading" class="projects-heading">
                <span data-lag="0.34" data-text="P">P</span>
                <span data-lag="0.3" data-text="r">r</span>
                <span data-lag="0.26" data-text="o">o</span>
                <span data-lag="0.24" data-text="j">j</span>
                <span data-lag="0.24" data-text="e">e</span>
                <span data-lag="0.26" data-text="c">c</span>
                <span data-lag="0.3" data-text="t">t</span>
                <span data-lag="0.34" data-text="s">s</span>
              </p>
            </div>
          </Box>

          <Box marginBottom="0.75rem" className="hover-tap-text"><h2>{props.hoverORtapPJS}</h2></Box>

          <Box className="flexProjectsContainer">
            {projectsList.map ((project) => (
              <ProjectStack
                /* key={index} */
                smoother={props.smoother}
                key={project.title}
                id={project.id}
                title={project.title}
                year={project.year}
                client={project.client}
                description={project.description}
                servicesCard={project.servicesCard}
                services={project.services}
                popover={project.popover}
                image={project.image}
                imageset={project.imageset}
                icon={project.icon}
                html={project.html}
                css={project.css}
                react={project.react}
                reactNative={project.reactNative}
                foundation={project.foundation}
                flash={project.flash}
                premiere={project.premiere}
                aftereffects={project.aftereffects}
                sqlite={project.sqlite}
                link={project.link}
                linktext={project.linktext}
                bgColor={props.bgColor}
                color={props.color}
              />
            ))}
          </Box>

        </VStack>

        <Box
          position="relative"
          bg="transparent"
          width="100%"
          top="-0.01vw"
          /* marginTop="-2px" */
          /* marginBottom="-2px" */
          zIndex="100"
          /* border="2px solid yellow" */
        >
          <Box
            height="5vw"
            width="100%"
            /* marginBottom="3vw" */
            backgroundColor="rgb(96, 0, 0, 0.85)"
            backdropFilter="blur(5px)"
            /* clipPath="polygon(0 50%, 0 0, 100% 0, 100% 50%, 50% 100%)" */
            clipPath="polygon(0 50%, 0 0, 100% 0, 100% 50%, 53% 90%, 50% 100%, 47% 90%)"
            /* clipPath="polygon(0 50%, 0 0, 100% 0, 100% 50%, 90% 35%, 50% 100%, 10% 35%)" */
            /* clipPath="polygon(0 0, 100% 0, 100% 50%, 95% 55%, 85% 45%, 50% 100%, 15% 45%, 5% 55%, 0 50%)" */
            webkit-clip-path="polygon(0 50%, 0 0, 100% 0, 100% 50%, 90% 35%, 50% 100%, 10% 35%)"
          />
        </Box>

      </VStack>
    </section>
  );
};
export default Projects;